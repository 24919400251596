<template>
	<main>
		<div class="app-heading pd:md">
			<div v-t="'privacy_policy'" class="app-title"></div>
		</div>

		<section class="pd:md">
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				We, {{ appName }} ( hereinafter, "we", "our" and"us" ), have enacted the following privacy policy ( hereinafter, "this Privacy Policy" ) on the appropriate handling of information including your personal information ( hereinafter, "Information, etc".) in connection with the services provided by our company. We take the private nature of your personal information very seriously.
			</div>

			<b>Gathering of Information</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				Like most website operators, we collect non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring sites, and the date and time of each visitor request. Our purpose in collecting non-personally indentifying information is to better understand how our visitors use its website.

				We also collect potentially personally-identifying information like Internet Protocol ( IP ) addresses. We do not use such information to identify its visitors, and does not disclose such information, other than under the same circumstances that it uses and discloses personally-identifying information, as described below.

				Certain visitors to our website choose to interact with us in ways that require us to gather personally-identifying information. We collect information from you when you register on our site or fill out required information. You may, however, visit our site anonymously. We collect information from your such as Mobile Phone Number. In each case, we collect such information only insofar as is necessary or appropriate to fulfil the purpose of the visitor's interaction with us. We do not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
			</div>

			<b>Protection of Information</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				We implement a variety of security measures the safety of your personal information when you access your personal information. We take all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
			</div>

			<b>Cookies</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. We use cookies to remember who you are when you login, to track your browsing patterns and to customize your experience on our site. The type of information we collect depends on what information and services you access and use, but generally do not contain or are not tied to your personal information. We may also use cookies for anonymous profiling purposes to tailor advertising to your preferences. If you do not wish to receive cookies, you can set your browser so that your computer does not accept them although this may prevent access to or limit some of the information and usability of our site.
			</div>

			<b>Ads</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				Ads appearing on our site may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy covers the use of cookies by {{ appName }} and does not cover the use of cookies by any advertisers.
			</div>

			<b>Third Party Links</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				Occasionally, at our discretion, we may include or offer third party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
			</div>

			<b>Your Consent</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				By using our site, you consent to our Privacy Policy.
			</div>

			<b>Privacy Policy Changes</b>
			<div class="pd:md" style="padding-left: 0; padding-right: 0">
				If we decide to change our privacy policy, we will post those changes on this page. This policy was last modified on 1 March 2019.
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: 'privacy_policy',
	inject: ['$util'],
	data () {
		return {
			appName: this.$i18n.t('brand_name')
		}
	},
	created () {
		this.$util.scrollToTop()
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;

	> * {
		&:first-child { padding-top: 0 }
		&:last-child { padding-bottom: 0 }
	}
}
</style>
